import React, { Component } from 'react';
import RichTextInput from 'ra-input-rich-text';
import { Create, SimpleForm, TextInput } from 'react-admin';
import { S3File } from '../s3';
import { CircularProgress } from '@material-ui/core';

var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
    ['link', 'image'],
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
    [{ 'theme': 'bubble'}]
  
    ['clean']                                         // remove formatting button
  ];

class BlogCreate extends Component{
    constructor(props){
        super(props)
        this.state = {
            cover_image:'',
            progress:false,
            author_image:'',
            progress_author_image:false,
        }
    }

    showLoader = (progress) => {
        this.setState({progress,})
    }

    showLoaderAuthor = (progress_author_image) => {
        this.setState({progress_author_image,})
    }
    
    changeImage = (url, progress) =>{
        this.setState({cover_image:url, progress,})
    }

    changeImageAuthor = (url, progress_author_image) =>{
        this.setState({author_image:url, progress_author_image,})
    }

    render(){

        const { cover_image, progress , author_image, progress_author_image } = this.state;

        let image;
        if (progress) {
            image = <CircularProgress className="progress"/>
        } else {
            image = <img src={`https://construcaribe.s3.amazonaws.com/${cover_image}`} className="margin-left-40px" />
        }

        let authorImage;
        if (progress_author_image) {
            authorImage = <CircularProgress className="progress"/>
        } else {
            authorImage = <img src={`https://construcaribe.s3.amazonaws.com/${author_image}`} className="margin-left-40px" />
        }


        return(
            <Create title={'Crear Blog'} {...this.props}>
                <SimpleForm>
                    <TextInput source="title" label="Titulo"/><br></br>
                    <TextInput source="author" label="Autor"/><br></br>

                    <S3File 
                    cover_image={this.state.author_image} 
                    changeImage={this.changeImageAuthor} 
                    progress={progress_author_image} 
                    showLoader={this.showLoaderAuthor} 
                    textButton={"Subir foto autor"}/>
                    <br></br>
                    
                    <div className="content_image">
                       {authorImage}
                    </div>

                    <TextInput 
                    source="author_image" 
                    defaultValue={this.state.author_image} 
                    style={{display: 'none'} }
                    />


                    <S3File cover_image={this.state.cover_image} changeImage={this.changeImage} progress={progress} showLoader={this.showLoader}/><br></br>
                    <div className="content_image">
                       {image}
                    </div>

                    <TextInput 
                    source="cover_image" 
                    defaultValue={this.state.cover_image} 
                    style={{display: 'none'} }/>

                    <RichTextInput 
                        source="content" 
                        validation={{ required: true }} 
                        toolbar={toolbarOptions}
                        label="Contenido"
                    />
                </SimpleForm>   
            </Create>
        )
    }
}

export default BlogCreate;