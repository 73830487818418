// import React, { Component } from "react";
// import RichTextInput from "ra-input-rich-text";
// import { Edit, SimpleForm, TextInput } from "react-admin";
// import { S3File } from "../s3";
// import { Grid } from "@material-ui/core";
// import { blogsService } from "../../services";
// import { message } from "antd";

// const ImageField = ({ record }) => {
//   return (
//     <img
//       src={`https://construcaribe.s3.amazonaws.com/${record.cover_image}`}
//       alt="Cover"
//       height="20%"
//       width="20%"
//     />
//   );
// };

// const ImageFieldAuthor = ({ record }) => {
//   return (
//     <img
//       src={`https://construcaribe.s3.amazonaws.com/${record.author_image}`}
//       alt="Cover"
//       height="20%"
//       width="20%"
//     />
//   );
// };

// var toolbarOptions = [
//   ["bold", "italic", "underline", "strike"], // toggled buttons
//   ["blockquote", "code-block"],
//   ["link", "image"],
//   [{ header: 1 }, { header: 2 }], // custom button values
//   [{ list: "ordered" }, { list: "bullet" }],
//   [{ script: "sub" }, { script: "super" }], // superscript/subscript
//   [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
//   [{ direction: "rtl" }], // text direction

//   [{ size: ["small", false, "large", "huge"] }], // custom dropdown
//   [{ header: [1, 2, 3, 4, 5, 6, false] }],

//   [{ color: [] }, { background: [] }], // dropdown with defaults from theme
//   [{ font: [] }],
//   [{ align: [] }],
//   [{ theme: "bubble" }]["clean"], // remove formatting button
// ];

// class BlogCreate extends Component {

//   // constructor(props){
//   //   super(props)
//   //   this.state = {
//   //     cover_image:'',
//   //     author_image:''
//   //   }
//   // }

//   handerCoverImage = async (urls) => {
//     // this.setState({...this.state, cover_image:urls})
//     const { match } = this.props;

//     console.log({urls})

//     await blogsService
//     .patch(match.params.id, {
//       cover_image: `${urls}`,
//     });

//     console.log("Cover");

//   }

//   handerAuthorImage = async (urls) => {
//     // this.setState({...this.state, author_image:urls})
//     // console.log(this.state);
//     const { match } = this.props;

//     console.log({urls})

//     await blogsService
//     .patch(match.params.id, {
//       author_image: `${urls}`,
//     });

//     console.log("Author");

//   }

//   // saveCoverBlog = async ( urls ) => {
//   //   const { match } = this.props;

//   //   await blogsService
//   //   .patch(match.params.id, {
//   //     author_image: `${urls}`,
//   //   })
//   //   .then();

//   // };

//   render() {
//     return (
//       <Edit title={"Crear Blog"} {...this.props}>
//         <SimpleForm>
//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <TextInput
//                 source="title"
//                 value="adfsads"
//                 label="Titulo"
//                 fullWidth
//               />
//             </Grid>
//             <Grid item xs={6} style={{ marginTop: 50 }}>
//               <S3File
//                 handleUploadFinish={this.handerCoverImage}
//                 service="blog"
//                 id={this.props.id}
//                 idComponent="componente_cover_image"
//                 path={"icons-common-zones"}
//               />
//             </Grid>

//             <Grid item xs={6} style={{ marginTop: 50 }}>
//               <TextInput source="title_slug" label="Slug" fullWidth />
//             </Grid>
//           </Grid>
//           <ImageField />

//           {/* ----------------------------------------------------------------------------- */}
//           <Grid container spacing={2}>
//             <Grid item xs={6}>
//               <TextInput
//                 source="author"
//                 value="adfsads"
//                 label="Autor"
//                 fullWidth
//               />
//             </Grid>
//             <Grid item xs={6} style={{ marginTop: 50 }}>
//               <S3File
//                 handleUploadFinish={this.handerAuthorImage}
//                 service="blog"
//                 id={this.props.id}
//                 idComponent="componente_author_image"
//                 path={"icons-common-zones"}
//                 textButton={"Subir imagen Autor"}
//               />
//             </Grid>
//           </Grid>
//           <ImageFieldAuthor />

//           <RichTextInput
//             source="content"
//             validation={{ required: true }}
//             toolbar={toolbarOptions}
//             label="Contenido"
//           />
//         </SimpleForm>
//       </Edit>
//     );
//   }
// }

// export default BlogCreate;

import React, { Component } from "react";
import RichTextInput from "ra-input-rich-text";
import { Edit, SimpleForm, TextInput } from "react-admin";
import { S3File } from "../s3";
import { Grid } from "@material-ui/core";
import { blogsService } from "../../services";
import { message } from "antd";

const ImageField = ({ url }) => {
  return (
    <img
      src={`https://construcaribe.s3.amazonaws.com/${url}`}
      alt="Cover"
      height="20%"
      width="20%"
    />
  );
};



const toolbarOptions = [
  ["bold", "italic", "underline", "strike"], // toggled buttons
  ["blockquote", "code-block"],
  ["link", "image"],
  [{ header: 1 }, { header: 2 }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ script: "sub" }, { script: "super" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ direction: "rtl" }],
  [{ size: ["small", false, "large", "huge"] }],
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  [{ color: [] }, { background: [] }],
  [{ font: [] }],
  [{ align: [] }],
  [{ theme: "bubble" }],
  ["clean"],
];

class BlogCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coverImage: "",
      authorImage: "",
    };
  }

  componentDidMount(){
    blogsService.get(this.props.id)
    .then(data => this.setState({
      coverImage : data.cover_image,
      authorImage: data.author_image
    }), e => console.log(e.error)); 
  }

  handleCoverImage = async (urls) => {
    const { id } = this.props;

    if (!id) {
      console.error("ID no encontrado en props");
      return;
    }

    try {
      await blogsService.patch(id, { cover_image: urls });
      message.success("Imagen de portada actualizada correctamente");

      this.setState((prevStatus) => ({
        coverImage: urls,
        authorImage: prevStatus.authorImage,
      }));

      console.log(this.state);
    } catch (error) {
      message.error("Error al actualizar la imagen de portada");
      console.error("Error en la actualización de cover_image:", error);
    }
  };

  handleAuthorImage = async (urls) => {
    const { id } = this.props;
    if (!id) {
      console.error("ID no encontrado en props");
      return;
    }

    try {
      await blogsService.patch(id, { author_image: urls });
      message.success("Imagen del autor actualizada correctamente");

      this.setState((prevStatus) => ({
        coverImage: prevStatus.coverImage,
        authorImage: urls,
      }));

      console.log(this.state);
    } catch (error) {
      message.error("Error al actualizar la imagen del autor");
      console.error("Error en la actualización de author_image:", error);
    }
  };

  render() {
    return (
      
      <Edit title={"Crear Blog"} {...this.props}>
        <SimpleForm>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput source="title" label="Título" fullWidth />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 50 }}>
              <S3File
                handleUploadFinish={this.handleCoverImage}
                service="blog"
                idComponent="componente_cover_image"
                path={"icons-common-zones"}
                textButton={"Subir imagen de portada"}
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 50 }}>
              <TextInput source="title_slug" label="Slug" fullWidth />
            </Grid>
          </Grid>
          <ImageField url={this.state.coverImage}/> 

          {/* ----------------------------------------------------------------------------- */}
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextInput source="author" label="Autor" fullWidth />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 50 }}>
              <S3File
                handleUploadFinish={this.handleAuthorImage}
                service="blog"
                idComponent="componente_author_image"
                path={"icons-common-zones"}
                textButton={"Subir imagen del autor"}
              />
            </Grid>
          </Grid>
          <ImageField url={this.state.authorImage}/> 


          <RichTextInput
            source="content"
            validation={{ required: true }}
            toolbar={toolbarOptions}
            label="Contenido"
          />
        </SimpleForm>
      </Edit>
    );
  }
}

export default BlogCreate;
