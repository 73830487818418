import React, { Component } from 'react';
// import { notification , message, Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import S3Uploader from 'react-s3-uploader';

const { REACT_APP_API_ENDPOINT } = process.env;
class App extends Component {

  state = {
    progress: null
  };

  componentDidMount() {
    // console.log(this.props, '****************+')
  }

  onUploadStart = (file, next) => {
    this.setState({ name_file: file.name })
    next(file);
    // console.log('-------', file)
  }

  onSignedUrl = (...props) => {
    // console.log('signed url', props)
  }

  onUploadProgress = (progress, ...props) => {
    // console.log('up progress', progress)
    this.setState({ progress });
  }

  onUploadError = (error) => {
    // console.log('on error----', error)
    // notification.error({ message: error });
  }

  onUploadFinish = (urls) => {
    this.props.handleUploadFinish(urls.fileKey)
  }


  render() {
    const { file = {}, match } = this.props;
    const { progress } = this.state;
    // console.log('file', file)
    if (file.name) return (
      <div className="s3Button mr-2">
        {file.name}
      </div>
    );
    return (
      <div className="mr-2">
        <label
          htmlFor={this.props.idComponent}
          className="flat-button-file"
          variant="outlined"
          color="primary"
        >{this.props.textButton || "Subir imagen"}</label>
        <label className="s3Button">
          <S3Uploader
            id={this.props.idComponent}
            signingUrl="/s3Client/sign"
            signingUrlMethod="GET"
            accept="*/*"
            s3path={`${this.props.path}/${match.params.id}/images/`}
            preprocess={this.onUploadStart}
            onSignedUrl={this.onSignedUrl}
            onProgress={this.onUploadProgress}
            onError={this.onUploadError}
            onFinish={this.onUploadFinish}
            signingUrlWithCredentials={true}      // in case when need to pass authentication credentials via CORS
            uploadRequestHeaders={{ 'x-amz-acl': 'public-read' }}  // this is the default
            contentDisposition="auto"
            scrubFilename={(filename) => filename.replace(/[^\w\d_\-.]+/ig, '')}
            server={`https://${REACT_APP_API_ENDPOINT}`}
            // inputRef={cmp => this.uploadInput = cmp}
            autoUpload={true}
            className="s3-uploader"
            style={{ visibility: 'hidden' }}
          />
        </label>
      </div>
    );
  }
}

export default withRouter(App);
