import React, { useState, useEffect, useRef } from 'react';
import {
    Table,
    message,
    Row,
    Col,
    Tooltip,
    Button,
    Modal,
    Divider,
} from "antd";
import { Wrapper, SearchBox, RoundedButton, WrapperFilters } from "./Styles";
import { EyeOutlined } from "@ant-design/icons"
/* import { exportToCsv, exportTableToPdf } from "../../../utils/Helper"; */
/* import { FileUploader } from "../"; */
import { getService } from "../../../services/";
import { Route, withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { MyModal } from '../MyModal';
import uuid from "react-uuid";
import qs from "qs";
import { URL_BASE } from "../../../constants/"
import _ from "lodash";
const { confirm } = Modal;
const LIMIT = 50;
const defaultState = {
    actions: {
        edit: true,
        create: true,
        delete: true
    },
    pagination: {
        //showSizeChanger: true,
        showTotal: total => `Total ${total} registros`,
        defaultCurrent: 1,
        current: 1,
        defaultPageSize: LIMIT,
        //pageSizeOptions: ['10', '20', '30', '40', '50']
    }
}
function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}
const Grid = ({ source, sorterAll, showId = true, searchKey = "q", primaryKey = "id", mode = "default", report = {}, filterDefaultValues = {}, initialValues = {}, custom = false, edit, create, actions = defaultState.actions, columns, searchText, search = true, refresh = true, extra, exportCsv = false, importCsv = true, model, path, ...props }) => {

    const idComponent = uuid();
    const myRef = useRef();
    const [pagination, setPagination] = useState(defaultState.pagination);
    const [filters, setFilters] = useState({});
    const [sorter, setSorter] = useState({});
    const [dataSource, setDataSource] = useState(props.dataSource || []);
    const [loading, setLoading] = useState(props.loading || false);
    const [exporting, setExport] = useState(false);
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState();

    const navigate = path => {
        let { history } = props;
        history.replace(path);
    }
    const itemLayout = {
        gridCol: {
            xl: { span: 24 },
            sm: { span: 24 },
            xs: { span: 24 },
        },
        formCol: {
            xl: { span: 24 },
            sm: { span: 24 },
            xs: { span: 24 },
        },
    };
    const handleDelete = (id, record) => {
        if (!source && props.onDelete)
            props.onDelete(id, record);
        if (source && id) {
            confirm({
                title: "Atención",
                content: "Desea eliminar el registro?",
                onOk() {
                    const service = getService(source);
                    service.remove(id)
                        .then(response => {
                            getData();
                            message.info("Record deleted");
                        })
                        .catch(err => message.error(err.message));
                },
                onCancel() { }
            });
        }
    };
    const handleActions = ({ name, path }) => {
        if (name) {
            return navigate(`/${props.basePath || "dashboard"}${path || "/" + source}/${name}`);
        }
        if (path) {
            return navigate(`/${props.basePath || "dashboard"}${path}`);
        }
    }
    const handleSearch = value => {
        setFilters({
            [searchKey]: value ? value : undefined
        });
        getData({
            query: {
                [searchKey]: value ? value : undefined,
                ...filterDefaultValues
            }
        });
    };
    const handleOnChange = (paginator, new_filters, sorter) => {
        let pager = { ...pagination };
        pager.current = paginator.current;
        setPagination(pager);
        setFilters({ ...filters, ...new_filters });
        if (sorter.field)
            setSorter({
                [sorter.field]: sorter.order == "ascend" ? 1 : -1
            });
    };
    const handleOnSearch = _.debounce(value => handleSearch(value), 500);
    const onChange = (field, value) => {
        setFilters({
            ...filters,
            [field]: value || undefined
        });
    }
    const handleExport = () => {
        setExport(true);
        let params = {
            query: {
                ...filterDefaultValues,
                $sort: sorter,
                $limit: 500000
            },
            ...filters
        };
        if (source) {
            /* const service = getService(source);
            service.find(params).then(({ data, meta }) => {
                console.log(data);
                exportToCsv(data, {
                    title: props.reportName || "Report"
                });
                setExport(false);
            }); */
            const service = getService("export-excel");
            let headers = report.columns || columns || [];
            service.create({
                report: {
                    name: props.reportName,
                    service: source,
                    columns: headers.filter(col => (typeof col.export != "undefined" ? col.export : true))
                        .map(it => ({
                            ...it,
                            dataIndex: it.dataIndex || it.source,
                            title: it.title || it.label,
                        })),
                    ...report
                },
                ...params
            })
                .then(({ url, fileName }) => {
                    let query = { url, fileName };
                    fetch(`https://${URL_BASE}/signed-url?${qs.stringify(query)}`, {
                        method: 'GET',
                        headers: new Headers({
                            "Authorization": localStorage.getItem("feathers-jwt")
                        })
                    })
                        .then(response => response.blob())
                        .then(blob => {
                            setExport(false);
                            var url = window.URL.createObjectURL(blob);
                            var a = document.createElement('a');
                            a.href = url;
                            a.download = fileName;
                            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                            a.click();
                            a.remove();  //afterwards we remove the element again    
                        });
                });
        }
    }
    const getData = params => {
        let token = localStorage.getItem("feathers-jwt");
        if (!token) return;
        if (props.dataSource) {
            return setLoading(false);
        }
        if (!loading) {
            setLoading(true);
            if (!params) {


                params = {
                    query: {
                        $limit: LIMIT,
                        ...filters,
                        ...filterDefaultValues,
                        $sort: sorter
                    }
                };
            }
            if (source) {
                const service = getService(source);
                service.find(params).then(({ data, meta, total }) => {
                    setLoading(false);
                    let pager = { ...pagination };
                    pager.total = meta ? meta.TotalRecords : total;
                    setPagination(pager);
                    setDataSource(data);
                })
                    .catch(err => {
                        /* message.error(err.message); */
                        setLoading(false);
                    });

            }
        }
    };
    const handleEdit = id => {
        if (source) {
            const service = getService(source);
            if (service && id)
                service.remove(id)
                    .then(response => {
                        setLoading(false);
                        getData();
                    })
                    .catch(err => {
                        /* message.error(err.message); */
                        setLoading(false);
                    });
        }
    }
    const handleOnSubmit = (err, data) => {
        if (!err && !record)
            setRecord(data);
        getData();
    }
    useEffect(() => {
        if (props.dataSource) {
            setLoading(false)
            setDataSource(props.dataSource)
        }
    }, [props.dataSource]);
    useEffect(() => {
        if (props.loading) {
            getData();
        }
    }, [props.loading]);
    useEffect(() => {
        let $skip = pagination.current || pagination.defaultCurrent || 1;
        getData({
            query: {
                $limit: LIMIT,
                $skip: $skip > 1 ? (($skip - 1) * LIMIT) : undefined,
                ...filters,
                ...filterDefaultValues,
                $sort: sorter
            }
        });
    }, [pagination.current, sorter, filters]);
    return (<Wrapper>
        <Row gutter={4} >
            {visible && mode == "inner" && <Col id={idComponent} {...itemLayout.formCol}>
                <div ref={myRef}>
                    {
                        record && record._id && edit ? React.cloneElement(edit, {
                            id: record._id,
                            initialValues,
                            redirect: false,
                            record,
                            onSubmit: handleOnSubmit
                        }) : React.cloneElement(create, {
                            redirect: false,
                            initialValues,
                            onSubmit: handleOnSubmit,
                        })
                    }
                </div>
            </Col>
            }
            <Col style={{
                margin: "10px 0px"
            }} {...itemLayout.gridCol}>
                <h2>{props.title}</h2>
                <Row
                    style={{
                        margin: "10px 0px"
                    }}
                    gutter={16}
                    type="flex"
                    justify="space-between"
                    align="middle"
                >
                    <Col>
                        <Row type="flex" gutter={16}>
                            {search && <SearchBox
                                allowClear={true}
                                placeholder={searchText || "Buscar..."}
                                onSearch={value => handleSearch(value)}
                                onChange={e => handleOnSearch(e.target.value)}
                                style={{ width: 200 }}
                            />}

                        </Row>
                    </Col>
                    <Col>
                        {extra}
                        {
                            exportCsv && <Tooltip placement="bottom" title="Export Excel">
                                <RoundedButton
                                    loading={exporting}
                                    style={{
                                        margin: "0px 10px",
                                        background: "#1dbf73",
                                        border: "1px solid #1dbf73"
                                    }}
                                    icon="file-excel"
                                    type="primary"
                                    onClick={handleExport}
                                />
                            </Tooltip>
                        }
                        {
                            /* importCsv && model && <Tooltip placement="bottom" title="Upload Csv">
                                <FileUploader
                                    {...model}
                                    onSubmit={getData}
                                    loading={exporting}
                                />
                            </Tooltip> */
                        }
                        {
                            create && actions.create && <Tooltip placement="bottom" title={props.addNewText || (!visible ? "Add New" : "Cancel")}>
                                <RoundedButton
                                    icon={(!visible ? "plus" : "close")}
                                    type={(!visible ? "primary" : "danger")}
                                    onClick={() => {
                                        setRecord(null);
                                        setVisible(visible => !visible);
                                        /* window.scrollTo(0, myRef.current ? myRef.current.offsetTop : 0) */
                                        /* window.location.hash = idComponent; */
                                        if (mode == "default")
                                            navigate(`/${props.basePath || "dashboard"}${path || "/" + source}/create`);
                                    }}
                                >
                                    {props.addNewText || (!visible ? "Add New" : "Cancel")}
                                </RoundedButton>
                            </Tooltip>
                        }
                        {
                            refresh && <Button
                                type="primary"
                                icon={!loading && "reload"}
                                loading={loading}
                                onClick={() => getData()}
                            >
                                {!loading ? "Recargar" : "Cargando"}
                            </Button>
                        }

                    </Col>
                </Row>
                <WrapperFilters style={{
                    margin: "10px 0px"
                }}
                    gutter={2}
                    type="flex"
                    justify="start"
                    align="middle">
                    {props.filters &&
                        React.Children.map(props.filters.props.children, (it, index) => {
                            let { name, source } = it.props;
                            name = name || source;
                            return <Col key={index}>
                                {React.cloneElement(it, {
                                    onChange: (e, value) => {
                                        if (e)
                                            value = e.target ? e.target.value : value;
                                        onChange(name || "field-" + index, value != name ? value : undefined)
                                    }
                                })}
                            </Col>
                        })
                    }
                </WrapperFilters>
                {props.filters && <Divider style={{
                    margin: "4px 0px 8px 0px"
                }} />}
                <Table
                    size="small"
                    onChange={handleOnChange}
                    pagination={typeof props.pagination !== "undefined" ? props.pagination : pagination}
                    rowKey={primaryKey}
                    loading={loading}
                    showHeader={props.showHeader}
                    columns={[
                        showId ? {
                            dataIndex: primaryKey,
                            sorter: true,
                            width: 80,
                            title: "ID",
                            /* ...getColumnSearchProps({
                                source: primaryKey,
                                placeholder: "ID"
                            }) */
                        } : undefined,

                        ...columns
                            .map(item => {
                                let sorter = item.sorter || item.sortable;
                                sorter = typeof sorterAll != "undefined" ? sorterAll : (typeof sorter == "undefined" && typeof sorter != "boolean");
                                return ({
                                    ...item,
                                    display: typeof item.display != "undefined" ? item.display : true,
                                    dataIndex: item.dataIndex || item.source,
                                    title: item.title || item.label,
                                    sorter,
                                    /* ...getColumnSearchProps(item) */
                                });
                            })
                            .filter(item => (item.display)),
                        typeof actions != "boolean" ?
                            (actions.edit ||
                                actions.show ||
                                actions.delete) && {
                                width: 100,
                                title: "Actions",
                                fixed:
                                    props.fixed ?
                                        typeof props.fixed == "boolean" ?
                                            props.fixed ? "right" : false : props.fixed
                                        : false,
                                render: (record) => {
                                    return (<Row type="flex" justify="start" gutter={4}>
                                        {
                                            actions.edit && <Col>
                                                <Button
                                                    type="dashed"
                                                    shape="circle"
                                                    icon="edit"
                                                    onClick={() => {
                                                        setRecord(record);
                                                        setVisible(true);
                                                        if (props.onChange) props.onChange(record);
                                                        let { history } = props;
                                                        history.push(`${props.basePath || ""}/${record[primaryKey]}`);
                                                    }} />
                                            </Col>
                                        }
                                        {
                                            actions.show && <Col>
                                                <Link
                                                    type="link"
                                                    shape="circle"
                                                    to={`${props.basePath || ""}/${record[primaryKey]}/show${Object.keys(filterDefaultValues).length > 0 ? "?" : ""}${filterDefaultValues && qs.stringify(filterDefaultValues)}`}
                                                    onClick={() => {
                                                        setRecord(record);
                                                        setVisible(true);
                                                        if (props.onChange) props.onChange(record);
                                                    }}>
                                                    <EyeOutlined size={"large"} type="eye" />
                                                </Link>
                                            </Col>
                                        }
                                        {
                                            actions.delete && <Col>
                                                <Button
                                                    icon="delete"
                                                    onClick={() => handleDelete(record[primaryKey])}
                                                    type="dashed"
                                                    shape="circle"
                                                />
                                            </Col>
                                        }

                                    </Row>)
                                }

                            } : actions ? [] : undefined,


                    ].filter(it => (typeof it != "undefined"))}
                    scroll={props.scroll || {
                        y: 600,
                        x: 400
                    }}
                    dataSource={dataSource}
                />
            </Col>

        </Row>
        {
            <MyModal
                title={props.id != "create" ? "Edit" : "Create"}
                closabled
                onCancel={() => setVisible(false)}
                visible={visible && mode == "modal"}
            >
                {props.id != "create" && edit ? React.cloneElement(edit, {
                    id: props.id
                }) : create}
            </MyModal>
        }
    </Wrapper >);
}
export default withRouter(Grid);